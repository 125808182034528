export const couponPages = {
    moa: {
        target: `MOA`,
        baseUrl: `/coupon/moa`,
        errorUrl: `/coupon/moa/error`,
        navigationUrl: `/coupon/moa/navigation`,
        locationUrl: `/coupon/moa/location`,
        kioskUrl: `/coupon/moa/kiosk`,
        googleMaps: {
            center: { lat: 37.581804, lng: 126.981233 },
            zoom: 17,
            label: `MOA Hanbok`,
            name: `모아한복`,
            loca: `32, Palpan-gil, Jongno-gu, Seoul, Republic of Korea`,
            lat: 37.583189,
            lng: 126.981293,
            startImg: require(`@/assets/img/coupon/moa/30.webp`),
            targetImg: require(`@/assets/img/coupon/moa/31.webp`),
        },
        footerLogoUrl: ``,
        backgroundSwiperUrl: [
            require(`@/assets/img/coupon/moa/moatitle1.webp`),
            require(`@/assets/img/coupon/moa/moatitle2.webp`),
            require(`@/assets/img/coupon/moa/moatitle3.webp`),
        ],
        logoUrl: require(`@/assets/img/coupon/moa/moa_logo.webp`),
        subLogoUrl: require(`@/assets/img/coupon/moa/moa_sublogo.webp`),
        navigationImg: [
            require(`@/assets/img/coupon/moa/1.webp`),
            require(`@/assets/img/coupon/moa/2.webp`),
            require(`@/assets/img/coupon/moa/3.webp`),
            require(`@/assets/img/coupon/moa/4.webp`),
            require(`@/assets/img/coupon/moa/5.webp`),
            require(`@/assets/img/coupon/moa/6.webp`),
            require(`@/assets/img/coupon/moa/7.webp`),
            require(`@/assets/img/coupon/moa/8.webp`),
        ],
        navigation: {
            1: {
                Korean: "모아한복을 나온 후, 왼쪽 방향으로 직진하세요.",
                English: "After leaving MOA Hanbok, go straight towards the left.",
                Japanese: "MOA韓服を出たら、左方向へまっすぐ進んでください。",
                Chinese: "离开MOA韩服后，请直行向左走。",
                Indonesian: "Setelah keluar dari MOA Hanbok, terus lurus ke arah kiri.",
                Thai: "หลังจากออกจาก MOA Hanbok ให้เดินตรงไปทางซ้าย。",
                Viet: "Sau khi rời MOA Hanbok, đi thẳng về phía bên trái.",
                Malay: "Selepas meninggalkan MOA Hanbok, teruskan lurus ke kiri.",
                French: "Après avoir quitté MOA Hanbok, continuez tout droit vers la gauche.",
                Portuguese: "Após sair da MOA Hanbok, siga reto para a esquerda.",
                Spanish: "Después de salir de MOA Hanbok, vaya recto hacia la izquierda."
            },
            2: {
                Korean: "한옥 건물에서 왼쪽 골목길로 좌회전하세요",
                English: "Turn left into the alleyway next to the Hanok building.",
                Japanese: "韓屋建物の左の小道に左折してください。",
                Chinese: "在韩屋建筑物的左边巷子左转。",
                Indonesian: "Belok kiri ke gang di sebelah bangunan Hanok.",
                Thai: "เลี้ยวซ้ายเข้าซอยข้างอาคาร Hanok.",
                Viet: "Rẽ trái vào con hẻm bên cạnh tòa nhà Hanok.",
                Malay: "Belok kiri ke lorong di sebelah bangunan Hanok.",
                French: "Tournez à gauche dans la ruelle à côté du bâtiment Hanok.",
                Portuguese: "Vire à esquerda no beco ao lado do edifício Hanok.",
                Spanish: "Gire a la izquierda en el callejón junto al edificio Hanok."
            },
            3: {
                Korean: "골목길의 끝까지 50m 정도 계속 걸어갑니다.",
                English: "Continue walking about 50 meters to the end of the alley.",
                Japanese: "小道の終わりまで約50メートル歩き続けてください。",
                Chinese: "继续走大约50米直到小巷的尽头。",
                Indonesian: "Terus berjalan sekitar 50 meter hingga ujung gang.",
                Thai: "เดินต่อไปประมาณ 50 เมตรจนถึงท้ายซอย。",
                Viet: "Tiếp tục đi bộ khoảng 50 mét đến cuối hẻm.",
                Malay: "Teruskan berjalan kira-kira 50 meter hingga ke hujung lorong.",
                French: "Continuez à marcher environ 50 mètres jusqu'au bout de l'allée.",
                Portuguese: "Continue caminhando cerca de 50 metros até o final do beco.",
                Spanish: "Continúe caminando unos 50 metros hasta el final del callejón."
            },
            4: {
                Korean: "골목길의 끝까지 걸어가면 큰 도로를 만날 수 있습니다. 이때 오른쪽으로 가세요",
                English: "When you reach the end of the alley, you will find a large road. Turn right here.",
                Japanese: "小道の終わりに着くと、大きな道があります。ここで右に行ってください。",
                Chinese: "走到小巷的尽头，你会看到一条大路。在这里向右转。",
                Indonesian: "Ketika Anda mencapai ujung gang, Anda akan menemukan jalan besar. Belok kanan di sini.",
                Thai: "เมื่อคุณเดินถึงท้ายซอย คุณจะพบกับถนนใหญ่ เลี้ยวขวาที่นี่。",
                Viet: "Khi bạn đến cuối hẻm, bạn sẽ thấy một con đường lớn. Rẽ phải ở đây.",
                Malay: "Apabila anda sampai di hujung lorong, anda akan menjumpai jalan besar. Belok kanan di sini.",
                French: "Lorsque vous atteignez la fin de l'allée, vous trouverez une grande route. Tournez à droite ici.",
                Portuguese: "Quando você chegar ao final do beco, encontrará uma grande estrada. Vire à direita aqui.",
                Spanish: "Cuando llegues al final del callejón, encontrarás una carretera grande. Gire a la derecha aquí."
            },
            5: {
                Korean: "100미터 정도를 걷다보면, 왼편에 횡단보도를 만날 수 있습니다. 이 횡단보도를 건너주세요",
                English: "Walk about 100 meters, and you will find a crosswalk on the left. Please cross it.",
                Japanese: "100メートルほど歩くと、左側に横断歩道があります。それを渡ってください。",
                Chinese: "走大约100米，你会在左边找到一个人行横道。请过马路。",
                Indonesian: "Berjalan sekitar 100 meter, dan Anda akan menemukan zebra cross di sebelah kiri. Silakan menyeberang.",
                Thai: "เดินประมาณ 100 เมตร คุณจะพบทางม้าลายทางซ้าย กรุณาข้ามมัน。",
                Viet: "Đi bộ khoảng 100 mét, bạn sẽ thấy một vạch qua đường ở bên trái. Xin vui lòng qua đường.",
                Malay: "Berjalan kira-kira 100 meter, dan anda akan menjumpai lintasan pejalan kaki di sebelah kiri. Sila lintasi.",
                French: "Marchez environ 100 mètres, et vous trouverez un passage piéton à gauche. Veuillez le traverser.",
                Portuguese: "Caminhe cerca de 100 metros, e você encontrará uma faixa de pedestres à esquerda. Por favor, atravesse-a.",
                Spanish: "Camine unos 100 metros y encontrará un paso de peatones a la izquierda. Por favor, crúcelo."
            },
            6: {
                Korean: "이 골목을 따라 100미터 정도를 직진하세요",
                English: "Follow this alley for about 100 meters straight ahead.",
                Japanese: "この小道をまっすぐ約100メートル進んでください。",
                Chinese: "沿这条小巷直走大约100米。",
                Indonesian: "Ikuti gang ini sekitar 100 meter lurus ke depan.",
                Thai: "ตามซอยนี้ไปตรงๆ ประมาณ 100 เมตร。",
                Viet: "Theo con hẻm này thẳng khoảng 100 mét.",
                Malay: "Ikuti lorong ini sejauh kira-kira 100 meter lurus ke hadapan.",
                French: "Suivez cette ruelle sur environ 100 mètres tout droit.",
                Portuguese: "Siga por este beco por cerca de 100 metros em frente.",
                Spanish: "Siga este callejón unos 100 metros recto."
            },
            7: {
                Korean: "🏃🏻‍♀️💨",
                English: "🏃🏻‍♀️💨",
                Japanese: "🏃🏻‍♀️💨",
                Chinese: "🏃🏻‍♀️💨",
                Indonesian: "🏃🏻‍♀️💨",
                Thai: "🏃🏻‍♀️💨",
                Viet: "🏃🏻‍♀️💨",
                Malay: "🏃🏻‍♀️💨",
                French: "🏃🏻‍♀️💨",
                Portuguese: "🏃🏻‍♀️💨",
                Spanish: "🏃🏻‍♀️💨"
            },
            8: {
                Korean: "걷다보면 왼편에 스튜디오를 발견할 수 있어요!",
                English: "As you walk, you'll find the studio on your left!",
                Japanese: "歩いていると、左側にスタジオが見つかります！",
                Chinese: "当你走路时，你会在左边发现工作室！",
                Indonesian: "Saat Anda berjalan, Anda akan menemukan studio di sebelah kiri!",
                Thai: "ขณะที่คุณเดิน คุณจะพบสตูดิโออยู่ทางซ้ายของคุณ!",
                Viet: "Khi bạn đi bộ, bạn sẽ tìm thấy studio ở bên trái của bạn!",
                Malay: "Semasa anda berjalan, anda akan menemui studio di sebelah kiri anda!",
                French: "En marchant, vous trouverez le studio sur votre gauche !",
                Portuguese: "Enquanto você caminha, você encontrará o estúdio à sua esquerda!",
                Spanish: "Mientras caminas, encontrarás el estudio a tu izquierda!"
            }
        },
    },
};