<template>
    <div class="popup_bg" @click="closePopup">
        <div class="popup">
            <div class="popup_wrap">
                <p>{{ popupMessage }}</p>
                <button v-if="popupConfirm">{{ popupConfirm }}</button>
            </div>
        </div>
    </div>
</template>

<script setup>
import { defineEmits, defineProps } from "vue";
defineProps({
    popupMessage: {
        type: String,
        required: true,
    },
    popupConfirm: {
        type: String,
        required: false,
    },
});

const emit = defineEmits(["close"]);
const closePopup = () => emit("close");
</script>

<style scoped>
@keyframes popupBg {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes popupAlert {
    from {
        transform: translateY(-20px);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.popup_bg {
    animation: popupBg 0.4s forwards;
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 31%);
    z-index: 2000;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.popup {
    position: fixed;
    background: white;
    z-index: 2001;
    animation: popupAlert-6f910c96 0.4s forwards;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
    width: fit-content;
    height: min-content;
    margin: auto;
    border-radius: 8px;
    width: calc(100% - 20px);
    max-width: 440px;
}

.popup p {
    padding: 20px;
    line-height: 20px;
    text-align: center;
    white-space: pre-line;
}

.popup_wrap {
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 4px rgb(0 0 0 / 15%);
}

.popup_wrap button {
    background: blue;
    color: #fff;
    width: min-content;
    width: fit-content;
    display: block;
    margin: 0 auto;
    padding: 10px 25px;
    font-weight: 550;
    border-radius: 8px;
}
</style>