<template>
  <!--로딩스피너 3000-->
  <loading-spinner v-show="loading.isLoadingVisible" :loadingTimer="loading.loadingTimer" />
  <!--언어선택 2000-->
  <language-main v-show="language.isLanguageRequired" :language="language.currentLanguage"
    @setLanguage="language.setLanguage" />
  <!--팝업 1000-->
  <popup-alert v-show="popup.isPopupVisible" :popupMessage="popup.popupMessage" :popupConfirm="popup.popupConfirm"
    @close="popup.closePopup" />
  <router-view :language="language.currentLanguage" />
</template>

<script setup>
import languageMain from "@/components/language/languageMain.vue";
import popupAlert from "@/components/common/layout/popupAlert.vue";
import loadingSpinner from "@/components/common/layout/loadingSpinner.vue";
import { onMounted, provide, reactive } from "vue";

// 초기 랜딩시 로딩 스피너
const loading = reactive({
  isLoadingVisible: false,
  loadingTimer: undefined,
  closeTimer: function () {
    this.isLoadingVisible = false;
  },
  setLoadingTimer: function (timeout) {
    this.isLoadingVisible = true;
    setTimeout(() => {
      this.isLoadingVisible = false;
    }, timeout);
  },
  setLoadingSwitch: function (Boolean) {
    this.isLoadingVisible = Boolean;
  }
});

// 팝업함수
const popup = reactive({
  isPopupVisible: false,
  popupMessage: "",
  popupConfirm: "",
  openPopup: function (popupMessage, popupConfirm) {
    popup.isPopupVisible = true;
    popup.popupMessage = popupMessage;
    popup.popupConfirm = popupConfirm; // 오타 수정
    // 콜백함수 필요시 파라미터 추가하여 사용
  },
  closePopup: function () {
    popup.isPopupVisible = false;
  },
});

// 초기 앱 언어설정
const language = reactive({
  languageStorage: localStorage.getItem("seonbi_lang"),
  isLanguageRequired: undefined,
  currentLanguage: undefined,
  setLanguage: function (param) {
    localStorage.setItem("seonbi_lang", param); // 수정
    language.languageStorage = param;
    language.currentLanguage = param;
  },
});

onMounted(async () => {
  await loading.setLoadingTimer(900);
  if (!language.languageStorage) {
    // 언어선택 안되어있는경우 언어선택창 노출
    language.isLanguageRequired = true;
  } else {
    // 기존 로컬값 세팅되어있는경우 현재언어 설정함
    language.isLanguageRequired = false;
    language.currentLanguage = localStorage.getItem("seonbi_lang");
  }
});

provide("setLoadingTimer", loading.setLoadingTimer);
provide("setLoadingSwitch", loading.setLoadingSwitch);
provide("openPopup", popup.openPopup);
</script>
