<template>
  <div class="background" :style="`z-index:${props.backgroundValue.zIndex}`">

    <div class="background_imgbox" v-show="props.backgroundValue.background"></div>
    <div class="background_star" v-show="props.backgroundValue.star">

      <img v-for="index in 8" :key="index" src="@/assets/img/common/star.webp" />

    </div>
    <div class="background_rocket" v-show="props.backgroundValue.rocket">
      <img src="@/assets/img/common/rocket.webp" />
    </div>

    <div class="background_mt" v-show="props.backgroundValue.mountain">
      <img class="tree_left" src="@/assets/img/common/tree.webp" />
      <img class="tree_right" src="@/assets/img/common/tree.webp" />
      <img class="stone_bottom" src="@/assets/img/common/stone.webp" />
      <img class="left" src="@/assets/img/common/obong-left.webp" />
      <img class="right" src="@/assets/img/common/obong-right.webp" />
    </div>

  </div>
</template>
<script setup>
import { defineProps } from 'vue';
const props = defineProps({
  backgroundValue: {
    zIndex: {
      type: String,
      required: true,
    },
    background: {
      type: Boolean,
      defualt: true
    },
    star: {
      type: Boolean,
      default: false,
    },
    rocket: {
      type: Boolean,
      default: false,
    },
    mountain: {
      type: Boolean,
      default: false,
    },
  }
})
</script>
<style scoped>
.background {
  overflow: hidden;
  touch-action: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  position: fixed;
  pointer-events: none;
  touch-action: none;
}

.background_imgbox {
  width: 100%;
  height: 100%;
  background: url("@/assets/img/common/background.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.background_star img {
  width: 35px;
  position: absolute;
  z-index: 1;
  filter: brightness(1.05);
}

@keyframes moving1 {
  from {
    transform: translateY(-4px);
  }

  to {
    transform: translateY(4px);
  }
}

@keyframes moving2 {
  from {
    transform: translateY(-3.5px) rotate(50deg);
  }

  to {
    transform: translateY(3.5px) rotate(50deg);
  }
}

.background_star {
  z-index: 0;
}

.background_star img:nth-child(1) {
  left: calc(50% + 90px);
  top: 5%;
  animation: moving1 1.2s infinite alternate cubic-bezier(0.5, 0, 0.5, 1);
}

.background_star img:nth-child(2) {
  left: calc(50% - 140px);
  top: 13%;
  animation: moving2 1s infinite alternate-reverse cubic-bezier(0.5, 0, 0.5, 1);
}

.background_star img:nth-child(3) {
  top: 32%;
  left: calc(50% + 130px);
  animation: moving1 1s infinite alternate-reverse cubic-bezier(0.5, 0, 0.5, 1);
}

.background_star img:nth-child(4) {
  left: calc(50% - 170px);
  top: 45%;
  filter: saturate(0.8);
  animation: moving2 1.33s infinite alternate-reverse cubic-bezier(0.5, 0, 0.5, 1);
}

.background_star img:nth-child(5) {
  left: calc(50% - 30px);
  top: 73%;
  animation: moving1 1s infinite alternate cubic-bezier(0.5, 0, 0.5, 1);
}

.background_star img:nth-child(6) {
  left: calc(50% - 510px);
  top: 30%;
  animation: moving1 1s infinite alternate cubic-bezier(0.5, 0, 0.5, 1);
}

.background_star img:nth-child(7) {
  left: calc(50% - 440px);
  top: 64%;
  animation: moving1 1s infinite alternate cubic-bezier(0.5, 0, 0.5, 1);
}

.background_star img:nth-child(8) {
  right: calc(50% - 520px);
  top: 17%;
  animation: moving1 1s infinite alternate cubic-bezier(0.5, 0, 0.5, 1);
}

.background_rocket {
  position: absolute;
  animation: moving1 0.9s infinite alternate cubic-bezier(0.5, 0, 0.5, 1);
  top: 56%;
  right: 24%;
}

.background_rocket img {
  width: 55px;
}

.background_mt {
  position: absolute;
  bottom: 0;
  right: 0;
  top: 0;
  left: 0;
  width: 100%;
}

.background_mt .tree_left {
  position: absolute;
  width: 110px;
  bottom: 100px;
  left: -5px;
}

.background_mt .tree_right {
  position: absolute;
  right: -10px;
  bottom: 10px;
  width: 120px;
  transform: scaleX(-1);
}

.background_mt .stone_bottom {
  position: absolute;
  bottom: 0;
  width: 140px;
  right: 80px;
}

.background_mt .left {
  position: absolute;
  bottom: 0;
  width: 160px;
  left: 0;
}

.background_mt .right {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 140px;
}

.background_mt .bottom {
  position: absolute;
  bottom: -20px;
  height: 120px;
  rotate: 10deg;
  z-index: 0;
  right: calc(50% - 150px);
}

@media screen and (min-width: 480px) {
  .background_mt .tree_right {
    width: 150px;
  }

  .background_mt .right {
    width: 130px;
  }

  .background_mt .tree_left {
    width: 140px;
  }

  .background_mt .left {
    width: 170px;
  }

  .background .stone_bottom {
    width: 180px;
    right: 70px;
  }
}

@media screen and (min-width: 1024px) {
  .background_mt .tree_right {
    width: 230px;
    bottom: 180px;
  }

  .background_mt .right {
    width: 230px;
  }

  .background_mt .tree_left {
    width: 190px;
    bottom: 150px;
  }

  .background_mt .left {
    width: 230px;
  }

  .background .stone_bottom {
    width: 250px;
    right: 140px;
  }
}
</style>