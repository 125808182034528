<template>
  <!-- 랭귀지 모듈 진입시 로딩 -->
  <div :class="['language_module', { complete: state.languageModuleComplete }]">
    <main-background :backgroundValue="backgroundValue" />
    <!-- 안내 -->
    <div :class="['language_face', { complete: state.languageFaceComplete }]">
      <div class="title_imgbox">
        <img src="@/assets/img/common/logowithtop.webp" alt="선비넉장" />
      </div>
      <div class="action_openlang">
        <button @click="showLanguageList">LANGUAGE</button>
        <span class="triangle"></span>
      </div>
      <div class="copyright">
        <p>
          ⓒ Copyright 2024. 선비넉장 All Rights reserved.
          <br />Any other unauthorized use is not permitted.
        </p>
      </div>
    </div>
    <!-- 외국어 리스트 -->
    <ul :class="[
      'language_list',
      { on: state.languageListOn, complete: state.languageListComplete },
    ]">
      <li v-for="language in languageList" :key="language" @click="selectLanguage(language)">
        <img :src="getImageSrc(language)" />
        <p>{{ language }}</p>
      </li>
    </ul>
    <div :class="['welcome_message', { on: state.welcomeMessageOn }]" v-html="state.welcomeMessage"></div>
  </div>
</template>

<script setup>
import { defineEmits, reactive } from "vue";
import mainBackground from "@/components/common/layout/mainBackground.vue";

const emit = defineEmits(["setLanguage"]);

const backgroundValue = {
  zIndex: "1000",
  background: true,
  star: true,
  rocket: true,
  mountain: true,
};

// 언어 리스트
const languageList = [
  "Korean",
  "English",
  "Japanese",
  "Chinese",
  "Indonesian",
  "Thai",
  "Viet",
  "Malay",
  "French",
  "Portuguese",
  "Spanish",
];

// 랭귀지 UI 상태
const state = reactive({
  languageFaceComplete: false,
  languageListOn: false,
  languageListComplete: false,
  welcomeMessageOn: false,
  welcomeMessage: "",
  languageModuleComplete: false,
});

const welcomeMessages = {
  Korean: `<p>엣헴, 어서오게나... *-_-*</p>
            <p>여기는 선비넉장이라네</p>`,
  English: `<p>welcome!</p>
            <p>We are <strong>photo booth</strong><br>at bukchon hanok village!</p>`,
  Chinese: `<p>歡迎！</p>
            <p>這是位於北村韓屋村<strong>的自拍工作室。</strong></p>`,
  Japanese: `<p>嬉しいです！</p>
             <p>北村韓屋村に位置する <strong>「人生４カッ」</strong> です。</p>`,
  Indonesian: `<p>Halo!</p>
               <p>Ini <strong>adalah studio foto </strong>yang terletak di Desa Bukchon Hanok!</p>`,
  Thai: `<p style="font-weight:700">สวัสดี!</p>
         <p><strong>นี่คือสตูดิโอถ่ายภาพที่ตั้งอยู่ในหมู่บ้านบุกชอนฮันอก!</strong></p>`,
  Viet: `<p>Xin chào!</p>
         <p><strong>Đây là studio ảnh nằm ở làng Bukchon Hanok!</strong></p>`,
  Malay: `<p>selamat berkenalan!</p>
          <p><strong>Ini adalah studio foto yang terletak di Bukchon Hanok Village!</strong></p>`,
  French: `<p>Bienvenue!</p>
           <p>Nous sommes un <strong>photomaton</strong><br>au village de Bukchon Hanok!</p>`,
  Portuguese: `<p>¡bienvenido!</p>
               <p>¡Somos un <strong>matón de fotos</strong><br>en la aldea de bukchon hanok!</p>`,
  Spanish: `<p>bem-vindo!</p>
            <p>Somos uma <strong>cabine fotográfica</strong><br>na vila de Bukchon Hanok!</p>`,
};

const getImageSrc = (language) =>
  require(`@/assets/img/language/${language}.webp`);

// 언어 리스트 노출
const showLanguageList = () => {
  state.languageFaceComplete = true;
  state.languageListOn = true;
};

// 언어 선택 완료
const selectLanguage = async (language) => {
  state.languageListComplete = true;
  state.welcomeMessageOn = true;
  state.welcomeMessage = welcomeMessages[language] || "<p>Welcome!</p>"; // 기본 메시지 설정

  emit("setLanguage", language);

  // 첫 번째 대기: 1.2초
  await delay(1000);
  state.languageModuleComplete = true;
};

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
</script>

<style>
@import "@/assets/css/layout/language.css";
</style>
