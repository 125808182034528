<template>
    <div class="loading">
        <div class="loader">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px"
                y="0px" viewBox="0 0 512 512" style="enable-background: new 0 0 512 512" xml:space="preserve">
                <g>
                    <circle cx="256" cy="72.5" r="55" fill="#545fff"></circle>
                    <circle cx="440.97" cy="185.5" r="55" fill="#545fff"></circle>
                    <circle cx="358.78" cy="403.29" r="55" fill="#545fff"></circle>
                    <circle cx="153.22" cy="403.29" r="55" fill="#545fff"></circle>
                    <circle cx="71.03" cy="185.5" r="55" fill="#545fff"></circle>
                </g>
            </svg>
        </div>
    </div>
</template>
<style>
@keyframes loader {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.loading {
    width: 100%;
    height: 100vh;
    align-items: center;
    display: flex;
    justify-content: center;
    z-index: 1010;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    position: fixed;
    background: #fff;
}

.loading .loader {
    width: 47.5px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    animation: loader 0.6s infinite linear;
}
</style>